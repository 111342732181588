import React, { lazy } from "react";

const Home = React.lazy(() => import("../components/organisms/pages/Home"));
const Post = React.lazy(() => import("../components/organisms/pages/Post"));

export const routePortals = {
  HOME: "/",
  POST: "/fundraiser/:id",
};
export const routePortal = [
  {
    id: 1,
    route: routePortals.HOME,
    component: Home,
    permission: "dashboard",
  },
  {
    id: 2,
    route: routePortals.POST,
    component: Post,
  },
];
